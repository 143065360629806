import {Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import servicios from '../models/clients';
import {HttpClient} from '@angular/common/http';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'cronos-website';
  is_sending_mail: boolean = false;
  is_submited = false;

  // Form
  name: String;
  email: String;
  message: String;

  img_clients: any = servicios;
  default_lang = navigator.language.substring(0, 2) || window.navigator['userLanguage'].substring(0, 2) || 'es';

  constructor(private translate: TranslateService, private http: HttpClient) {
    translate.setDefaultLang(this.default_lang);
  }

  // <button (click)="useLanguage('en')">en</button>
  useLanguage(language: string) {
    this.default_lang = language;
    this.translate.use(language);
  }

  sendEmail(event, f) {
    event.preventDefault();
    console.log(f);
    let data = {
      service_id: 'ignacio_delgado_cronos_mobi',
      template_id: 'template_3Y3A8KZc',
      user_id: 'user_onzKonlOKGpgtSaM7Czl3',
      template_params: {
        'message_content': f.form.value.message,
        'from_name': f.form.value.email,
        'username': f.form.value.name,
        // 'g-recaptcha-response': '03AHJ_ASjnLA214KSNKFJAK12sfKASfehbmfd...'
      }
    };

    this.is_sending_mail = true;
    this.sendRequest(data).then((res) => {
      console.log(res);
      this.is_sending_mail = false;
      this.is_submited = true;
    }).catch((err) => {
      console.log(err);
      this.is_sending_mail = false;
      this.is_submited = true;
    });

  }


  sendRequest(data) {
    return new Promise((resolve, reject) => {

      this
        .http
        .post('https://api.emailjs.com/api/v1.0/email/send', data)
        // .map((res: any) => res.json())
        .subscribe((res: any) => {
          resolve(res);
        }, (err) => {
          reject(err);
        });

    });
  }

}
