export default [
  {
    'src': '',
    'img': 'assets/images/clients/avianca.png',
    'imgc': 'assets/images/clients/color/aviancaCOLOR.png',
  }, {
    'src': '',
    'img': 'assets/images/clients/banco_mundial.png',
    'imgc': 'assets/images/clients/color/banco_mundialCOLOR.png',
  }, {
    'src': '',
    'img': 'assets/images/clients/chatsalud.png',
    'imgc': 'assets/images/clients/color/chatsaludCOLOR.png',
  }, {
    'src': '',
    'img': 'assets/images/clients/claro.png',
    'imgc': 'assets/images/clients/color/claroCOLOR.png',
  }, {
    'src': '',
    'img': 'assets/images/clients/claxson.png',
    'imgc': 'assets/images/clients/color/claxsonCOLOR.png',
  }, {
    'src': '',
    'img': 'assets/images/clients/Digicel.png',
    'imgc': 'assets/images/clients/color/DigicelCOLOR.png',
  }, {
    'src': '',
    'img': 'assets/images/clients/color/foxCOLOR.png',
    'imgc': 'assets/images/clients/fox.png',
  }, {
    'src': '',
    'img': 'assets/images/clients/grupoq.png',
    'imgc': 'assets/images/clients/color/grupoqCOLOR.png',
  }, {
    'src': '',
    'img': 'assets/images/clients/masmovil.png',
    'imgc': 'assets/images/clients/color/masmovilCOLOR.png',
  }, {
    'src': '',
    'img': 'assets/images/clients/mercedes.png',
    'imgc': 'assets/images/clients/color/mercedesCOLOR.png',
  }, {
    'src': '',
    'img': 'assets/images/clients/movistar.png',
    'imgc': 'assets/images/clients/color/movistarCOLOR.png',
  }, {
    'src': '',
    'img': 'assets/images/clients/nissan.png',
    'imgc': 'assets/images/clients/color/nissanCOLOR.png',
  }, {
    'src': '',
    'img': 'assets/images/clients/paramount.png',
    'imgc': 'assets/images/clients/color/paramountCOLOR.png',
  }, {
    'src': '',
    'img': 'assets/images/clients/sony.png',
    'imgc': 'assets/images/clients/color/sonyCOLOR.png',
  }, {
    'src': '',
    'img': 'assets/images/clients/tigo.png',
    'imgc': 'assets/images/clients/color/tigoCOLOR.png',
  }, {
    'src': '',
    'img': 'assets/images/clients/tllevo.png',
    'imgc': 'assets/images/clients/color/tllevoCOLOR.png',
  }, {
    'src': '',
    'img': 'assets/images/clients/warner.png',
    'imgc': 'assets/images/clients/color/warnerCOLOR.png',
  }
];
